import axios from 'axios';


let API_URL = '';
let API_URL2 = '';
if (process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/khmer/';
   API_URL2 = 'http://localhost:8000/api/khmer/';
} else {
   API_URL = '//api.admin-siamprai.com/api/khmer/';
   API_URL2 = '//api.admin-siamprai.com/api/khmer/';
}

const getBonusByDatesKH = async (dates,token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
    const response = await axios.get(API_URL + `/getBonusByDatesKH/${dates.sDate}/${dates.eDate}`, config);
    return response.data;
 }

const approveBonusKH = async (data, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.post(API_URL + `approveBonusKH`, data, config);
    return response.data;
 };
 
 const updateStatusToIsPaid = async (data, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.patch(
       API_URL + `updateStatusToIsPaid`,
       data,
       config
    );
    return response.data;
 };

 const khmerService = {
   approveBonusKH,
    getBonusByDatesKH,
    updateStatusToIsPaid
 };
 
 export default khmerService;
 