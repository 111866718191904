import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import khmerService from './khmerService';
import Cookies from 'js-cookie';


const initialState = {
   commissions: [],
   commissions2: [],
   commission: {},
   binary: {},
   recommendLine: [],
   reccommendCommsion: [],
   comMobile: [],
   mathcing: [],
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

export const getBonusByDatesKh = createAsyncThunk(
    'commission/getBonusByDatesKH',
    async (dates, thunkAPI) => {
       try {
          const token = Cookies.get('token');
          return await khmerService.getBonusByDatesKH(dates,token);
       } catch (error) {
          const message =
             (error.response &&
                error.response.data &&
                error.response.data.message) ||
             error.message ||
             error.toString();
          return thunkAPI.rejectWithValue(message);
       }
    }
 );

 export const approveBonusKH = createAsyncThunk(
    'commission/approveBonus',
    async (  data , thunkAPI) => {
       try {
          const token = Cookies.get('token');
          return await khmerService.approveBonusKH(
              data,
             token
          );
       } catch (error) {
          const message =
             (error.response &&
                error.response.data &&
                error.response.data.message) ||
             error.message ||
             error.toString();
          return thunkAPI.rejectWithValue(message);
       }
    }
 );

 export const updateStatusToIsPaidKh = createAsyncThunk(
    'commission/updateStatusToIsPaid',
    async (  data , thunkAPI) => {
       try {
          const token = Cookies.get('token');
          return await khmerService.updateStatusToIsPaid(
              data,
             token
          );
       } catch (error) {
          const message =
             (error.response &&
                error.response.data &&
                error.response.data.message) ||
             error.message ||
             error.toString();
          return thunkAPI.rejectWithValue(message);
       }
    }
 );

 const khmerSlice = createSlice({
    name: 'khmer',
    initialState,
    reducer:{
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(getBonusByDatesKh.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBonusByDatesKh.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(getBonusByDatesKh.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(approveBonusKH.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(approveBonusKH.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions2 = action.payload;
         })
         .addCase(approveBonusKH.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateStatusToIsPaidKh.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateStatusToIsPaidKh.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;

         })
         .addCase(updateStatusToIsPaidKh.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
    }
 })


 export const { reset } = khmerSlice.actions;
export default khmerSlice.reducer;