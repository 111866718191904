import axios from 'axios';

//   const API_URL = 'http://localhost:5000/api/deposit/';

let API_URL = '';
if (process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/deposit/';
} else {
   API_URL = '//api.admin-siamprai.com/api/deposit/';
}

const createDeposit = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + 'create', data, config);
   return response.data;
};

const cancelDeposit = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(
      API_URL + `cancelDeposit/${data.dopositId}`,
      data,
      config
   );
   return response.data;
};

const approveDeposit = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const response = await axios.patch(
      API_URL + `approveDeposit/${data.userId}`,
      data,
      config
   );
   return response.data;
};

const updateFreeGift = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const response = await axios.patch(
      API_URL + `updateFreeGift/${data._id}`,
      data,
      config
   );
   return response.data;
};
const editFreeGift = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const response = await axios.patch(
      API_URL + `editFreeGift/${data._id}`,
      data,
      config
   );
   return response.data;
};

const getAllDeposits = async (page = 1, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const limit = 10;

   console.log('page :', page);
   console.log('limit :', limit);

   const response = await axios.get(`${API_URL}getAll?page=${page}&limit=${limit}`, config);
   return response.data;
};

const getDepositDetail = async (dates, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + `/getDepositDetail/${dates.sDate}/${dates.eDate}`,
      config
   );
   return response.data;
};

const getCancelsDeposits = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getCancel', config);
   return response.data;
};

const getTotalDepositRequest = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getTotalDepositRequest', config);
   return response.data;
};

const getDepositsById = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getById/${id}`, config);
   return response.data;
};

const getUserDepositsById = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getUserById/${id}`, config);
   return response.data;
};

const getCenterDeposits = async (month, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + `getCenterDeposits/${month}`,
      config
   );
   return response.data;
};
// const getDepositsById = async (userId, token) => {
//    const config = {
//       headers: {
//          Authorization: `Bearer ${token}`,
//       },
//    };

//    const response = await axios.get(
//       API_URL + `getDepositsById/${userId}`,
//       config
//    );
//    return response.data;
// };
const updateStatus = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(API_URL + `updateStatus`, { data }, config);
   return response.data;
};
const editDepostProducts = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.patch(
      API_URL + `editDepostProducts`,
      { data },
      config
   );
   return response.data;
};

const depositService = {
   createDeposit,
   getAllDeposits,
   getDepositsById,
   getUserDepositsById,
   updateStatus,
   getTotalDepositRequest,
   cancelDeposit,
   getCancelsDeposits,
   approveDeposit,
   getDepositDetail,
   updateFreeGift,
   getCenterDeposits,
   editDepostProducts,
   editFreeGift,
  
};

export default depositService;
